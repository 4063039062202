.navbar {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}

.link-hover:hover {
  color: black;
}

#dropdown-basic::after {
  display: none;
}
